import Rails from "@rails/ujs";
Rails.start();

function adjustHeight() {
  const vh = window.innerHeight * 0.01;
  const vw = window.innerWidth * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  document.documentElement.style.setProperty('--vw', `${vw}px`);
}

// Run the function on load and on resize
window.addEventListener('load', adjustHeight);
window.addEventListener('resize', adjustHeight);

console.log('✨');

